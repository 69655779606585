const resetPassword = async (email, otp, password, recaptchaValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DEV_API}/v1/iam/reset-password`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          otp,
          newPassword: password,
          gRecaptchaResponse: recaptchaValue
        })
      }
    );

    const data = await response.json();

    if (response.ok && data.status === 'success') {
      debugger;
      return data;
    } else {
      throw new Error(data.message || 'Failed to reset the password.');
    }
  } catch (error) {
    console.error('Error while resetting the password:', error);
    throw new Error(
      error.message ||
        'An unexpected error occurred while resetting the password. Please try again.'
    );
  }
};

export default resetPassword;
