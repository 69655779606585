import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Document, Page, pdfjs } from 'react-pdf';
import ApplyJobModal from '../ApplyJobModal';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useHelperBot } from '../HelperBot';
import { toggleScrollBarFormRootHTMLTag } from '../../utils/CommonFunctions';
import api from '../../utils/api';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ResumeUploadModal = ({
  isOpen,
  onClose,
  jobId,
  showApplyJobModal,
  setShowApplyJobModal,
  onProceed
}) => {
  const [file, setFile] = useState(null);
  // const [showApplyJobModal, setShowApplyJobModal] = useState(false);
  const [selectedParsedContent, setSelectedParsedContent] = useState(null);
  const [savedResumes, setSavedResumes] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentResumeUrl, setCurrentResumeUrl] = useState('');

  const { helperMessage } = useHelperBot();
  const isMounted = useRef(true);
  const currentAnalysisRef = useRef(null);

  useEffect(() => {
    if (isAnalyzing) {
      let arr = [
        'Hang tight, I’m just giving your resume a quick look-over. We’re almost done!',
        'Still here? Great! I’m just making sure everything looks perfect.',
        'Almost there, just putting on the final touches. You’re doing awesome!'
      ];
      helperMessage(arr[Math.floor(Math.random() * arr.length)]);
      return;
    }
  }, [isAnalyzing, showApplyJobModal]);

  useEffect(() => {
    const container = document.getElementById('uploadResumeModal');
    if (!container) {
      return;
    }

    const focusableElements = container.querySelectorAll(
      'input, button, select, textarea, a[href]'
    );
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    const handleKeyDown = (e) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        // Shift + Tab
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        // Tab
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    };

    container.addEventListener('keydown', handleKeyDown);

    return () => {
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        if (isResumeModalOpen) {
          setIsResumeModalOpen(false);
        } else if (isOpen) {
          onClose();
        }
      }
    };

    if (isOpen || isResumeModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, isResumeModalOpen, onClose]);

  const handleDownloadResume = async (resumeId) => {
    try {
      const resume = savedResumes.find((r) => r.id === resumeId);
      if (resume) {
        const objectKey = resume.resumeUrl;
        const response = await api.post('/v1/media/signed-url', { objectKey });
        const data = response.data;

        if (data.data.signedUrl) {
          const link = document.createElement('a');
          link.href = data.data.signedUrl;
          link.download = resume.name || 'resume.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          throw new Error(data.message);
        }
      }
    } catch (error) {
      console.error('Error downloading resume:', error);
      toast.error('Failed to download resume. Please try again later.');
    }
  };

  useEffect(() => {
    fetchSavedResumes();
  }, [isOpen]);

  const fetchSavedResumes = async () => {
    try {
      const response = await api.get('/v1/resume');
      const data = response.data;

      if (data.status === 'success' && Array.isArray(data.data.resume)) {
        const formattedResumes = data.data.resume.map((resume) => ({
          id: resume._id,
          name: resume.name || resume.originalName,
          createdAt: resume.createdAt,
          resumeUrl: resume.resume,
          parsedContent: resume.parsedResume?.parsedContent || null
        }));
        setSavedResumes(formattedResumes);
        if (formattedResumes.length > 0) {
          setSelectedResume(formattedResumes[0].id);
        }
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching resumes:', error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && !isFileNameDuplicate(selectedFile.name)) {
      setFile(selectedFile);
    } else {
      toast.error(
        'A file with this name already exists. Please choose a different file or rename it.'
      );
      event.target.value = null;
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && !isFileNameDuplicate(droppedFile.name)) {
      setFile(droppedFile);
    } else {
      toast.error(
        'A file with this name already exists. Please choose a different file or rename it.'
      );
    }
  };

  const isFileNameDuplicate = (fileName) => {
    return savedResumes.some(
      (resume) => resume.name.toLowerCase() === fileName.toLowerCase()
    );
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleUpload = async () => {
    if (file && !isFileNameDuplicate(file.name)) {
      try {
        setLoading(true);
        setIsAnalyzing(true);

        currentAnalysisRef.current = file.name;

        const formData = new FormData();
        formData.append('resume', file);

        const response = await api.post('/v1/resume/parse', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const uploadData = response.data;

        if (
          uploadData.message === 'Resume analysis started successfully' &&
          uploadData.data &&
          uploadData.data._id
        ) {
          const resumeId = uploadData.data._id;
          const currentAnalysisId = currentAnalysisRef.current;

          if (currentAnalysisId === file.name && isMounted.current) {
            const parsedContent = await pollForParsedContent(
              resumeId,
              file.name
            );

            if (currentAnalysisRef.current === file.name && isMounted.current) {
              const newResume = {
                id: resumeId,
                name: file.name,
                resumeUrl: uploadData.data.resume,
                createdAt: uploadData.data.createdAt,
                parsedContent: parsedContent
              };

              setSavedResumes((prev) => [newResume, ...prev]);
              setFile(null);
              setSelectedResume(newResume.id);
              toast.success('Resume uploaded successfully!');

              if (savedResumes.length === 0) {
                setSelectedParsedContent(parsedContent);
                setShowApplyJobModal(true);
                toggleScrollBarFormRootHTMLTag(true);
                onClose();
              }
            }
          }
        }
      } catch (error) {
        if (isMounted.current) {
          console.error('Upload error:', error);
          toast.error('Failed to upload resume. Please try again.');
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
          setIsAnalyzing(false);
        }
      }
    }
  };

  const pollForParsedContent = (resumeId, analysisId) => {
    return new Promise((resolve, reject) => {
      const pollInterval = 5000;

      const checkParsedContent = async () => {
        if (currentAnalysisRef.current !== analysisId || !isMounted.current) {
          reject(new Error('Analysis cancelled'));
          return;
        }

        try {
          const response = await api.get(`/v1/resume/${resumeId}`);
          const data = response.data;

          if (
            data.status === 'success' &&
            data.data.parsedResume &&
            data.data.parsedResume.parsedContent &&
            data.data.parsedResume.parsedContent.fullName
          ) {
            resolve(data.data.parsedResume.parsedContent);
          } else {
            if (
              currentAnalysisRef.current === analysisId &&
              isMounted.current
            ) {
              setTimeout(checkParsedContent, pollInterval);
            }
          }
        } catch (error) {
          if (currentAnalysisRef.current === analysisId && isMounted.current) {
            setTimeout(checkParsedContent, pollInterval);
          }
        }
      };

      checkParsedContent();
    });
  };

  const handleViewResume = async (resumeId) => {
    try {
      const resume = savedResumes.find((r) => r.id === resumeId);
      if (resume) {
        const objectKey = resume.resumeUrl;
        const response = await api.post('/v1/media/signed-url', { objectKey });
        const data = response.data;

        if (data.data.signedUrl) {
          setCurrentResumeUrl(data.data.signedUrl);
          setIsResumeModalOpen(true);
        } else {
          throw new Error(data.message);
        }
      }
    } catch (error) {
      console.error('Error fetching resume URL:', error);
      toast.error('Failed to load resume. Please try again later.');
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  const handleProceed = async () => {
    if (selectedResume) {
      try {
        setLoading(true);
        const response = await api.get(`/v1/resume/${selectedResume}`);
        const data = response.data;

        if (
          data.status === 'success' &&
          data.data.parsedResume &&
          data.data.parsedResume.parsedContent
        ) {
          setSelectedParsedContent(data.data.parsedResume.parsedContent);
          setShowApplyJobModal(true);
          toggleScrollBarFormRootHTMLTag(true);
          onClose();
        } else {
          toast.error('No parsed content available for the selected resume.');
        }
      } catch (error) {
        console.error('Error fetching parsed resume content:', error);
        toast.error('Failed to fetch parsed resume content. Please try again.');
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Please select a resume');
    }
  };

  const checkParsedContent = (resumeId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await api.get(`/v1/resume/${resumeId}`);
        const data = response.data;

        if (
          data.status === 'success' &&
          data.data.parsedResume &&
          data.data.parsedResume.parsedContent
        ) {
          clearInterval(intervalId);
          setLoading(false);
          handleParsedContent(data.data.parsedResume.parsedContent);
        }
      } catch (error) {
        console.error('Error checking parsed content:', error);
        clearInterval(intervalId);
        setLoading(false);
        toast.error('Failed to retrieve parsed content. Please try again.');
      }
    }, 5000);
  };

  const handleParsedContent = (parsedContent) => {
    // console.log('Parsed content:', parsedContent);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
      currentAnalysisRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      currentAnalysisRef.current = null;
      setIsAnalyzing(false);
      setLoading(false);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-[2] flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
          id="uploadResumeModal"
        >
          <div className="relative w-full max-w-md p-4 mx-auto md:max-w-lg">
            <div className="relative bg-white rounded-lg shadow-lg max-h-[90vh] flex flex-col">
              <div className="flex items-center justify-between p-4 border-b">
                <h3 className="text-xl font-semibold text-gray-900">
                  Upload Your Resume!
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  onClick={onClose}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="p-6 relative h-[100%] overflow-hidden flex flex-col">
                <div className="text-xs mb-4 text-justify">
                  Don't have a PDF resume? No problem! <br></br>Convert your DOC
                  or DOCX to PDF using{' '}
                  <a
                    href="https://www.ilovepdf.com/word_to_pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-medium text-primary-600"
                  >
                    this link
                  </a>{' '}
                  and return to upload.
                </div>
                <div
                  className={`border-2 border-dashed border-primary-400 rounded-lg p-4 text-center cursor-pointer hover:bg-primary-50 transition-colors ${
                    isAnalyzing ? 'opacity-50 pointer-events-none' : ''
                  }`}
                  onDrop={isAnalyzing ? null : handleDrop}
                  onDragOver={isAnalyzing ? null : handleDragOver}
                >
                  <input
                    type="file"
                    id="resumeUpload"
                    className="hidden"
                    onChange={handleFileChange}
                    accept=".pdf"
                    disabled={isAnalyzing}
                  />
                  <label
                    htmlFor="resumeUpload"
                    className={`cursor-pointer ${
                      isAnalyzing ? 'opacity-50 pointer-events-none' : ''
                    }`}
                  >
                    <svg
                      className="mx-auto h-12 w-12 text-primary-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mt-2 text-sm font-medium text-primary-600">
                      {isAnalyzing
                        ? 'Resume upload disabled during analysis'
                        : 'Click the button above or drop your resume in here.'}
                    </p>
                    <p className="mt-1 text-xs text-gray-500">
                      English resumes in PDF only. Max 5MB file size.
                    </p>
                  </label>
                </div>
                {file && (
                  <div className="mt-4">
                    <p className="text-sm text-gray-600 flex">
                      Selected file:{' '}
                      <div
                        className="text-primary-600 font-semibold text-truncate pl-2"
                        style={{ width: '80%' }}
                      >
                        {file.name}
                      </div>
                    </p>
                    <button
                      className={`mt-2 px-4 py-2 text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 ${
                        isAnalyzing ? 'opacity-50 cursor-not-allowed' : ''
                      } flex items-center justify-center`}
                      onClick={handleUpload}
                      disabled={isAnalyzing}
                    >
                      {isAnalyzing ? (
                        <>
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Analyzing...
                        </>
                      ) : (
                        'Upload'
                      )}
                    </button>
                    {isAnalyzing && (
                      <p className="mt-2 text-sm text-primary-600 font-semibold">
                        Hold on! We are analyzing your resume.
                      </p>
                    )}
                  </div>
                )}
                {savedResumes.length > 0 && (
                  <div className="mt-6 overflow-hidden flex flex-col h-[100%] relative pr-1">
                    <h4 className="text-lg font-semibold text-gray-900 mb-2">
                      My saved resumes
                    </h4>
                    <div className="relative overflow-y-scroll h-[100%]">
                      {savedResumes.map((resume, index) => (
                        <div
                          key={resume.id}
                          className="flex items-center mb-4 p-1"
                        >
                          <input
                            type="radio"
                            id={`resume-${resume.id}`}
                            name="savedResume"
                            value={resume.id}
                            checked={selectedResume === resume.id}
                            onChange={() => setSelectedResume(resume.id)}
                            className="mr-2"
                          />
                          <label
                            htmlFor={`resume-${resume.id}`}
                            className="text-sm text-gray-700 flex-grow w-[60%] overflow-hidden text-ellipsis"
                            title={resume.name}
                          >
                            {resume.name}
                          </label>
                          <button
                            className="ml-2 text-primary-500 hover:text-primary-700 text-sm"
                            onClick={() => handleViewResume(resume.id)}
                          >
                            View
                          </button>
                          <button
                            className="ml-2 text-primary-500 hover:text-primary-700 text-sm"
                            onClick={() => handleDownloadResume(resume.id)}
                          >
                            Download
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <button
                  className={`w-full mt-6 px-4 py-2 text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 ${
                    !selectedResume || isAnalyzing
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  onClick={handleProceed}
                  disabled={!selectedResume || isAnalyzing}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showApplyJobModal && (
        <ApplyJobModal
          isOpen={showApplyJobModal}
          jobId={jobId}
          onClose={() => {
            setShowApplyJobModal(false);
            toggleScrollBarFormRootHTMLTag(false);
          }}
          parsedResumeContent={selectedParsedContent}
          resumeId={selectedResume}
        />
      )}
      {isResumeModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg w-[92vw] sm:w-[80vw] h-[90vh] flex flex-col">
            <div className="flex justify-between items-center mb-4 border-b pb-2">
              <h2 className="text-2xl font-bold text-gray-900">Resume</h2>
              <button
                onClick={() => setIsResumeModalOpen(false)}
                className="button text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center z-50"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex-grow overflow-auto">
              <Document
                file={currentResumeUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                className="w-full md:flex md:flex-col md:items-center justify-center"
              >
                <Page
                  pageNumber={pageNumber}
                  className="border border-gray-300 shadow-md"
                />
              </Document>
            </div>
            <div
              className={`flex flex-col md:flex-row gap-4 ${
                numPages > 1 ? 'justify-center' : 'justify-end'
              } items-center mt-4 border-t pt-4 relative`}
            >
              {numPages > 1 && (
                <div className="flex justify-center items-center gap-4">
                  <button
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    className={`px-4 py-2 border rounded ${
                      pageNumber <= 1
                        ? 'text-gray-500 cursor-not-allowed'
                        : 'hover:bg-gray-200'
                    }`}
                  >
                    Previous
                  </button>

                  <span className="mx-2 px-4 py-2 border rounded">
                    Page {pageNumber} of {numPages}
                  </span>

                  <button
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                    className={`px-4 py-2 border rounded ${
                      pageNumber >= numPages
                        ? 'text-gray-500 cursor-not-allowed'
                        : 'hover:bg-gray-200'
                    }`}
                  >
                    Next
                  </button>
                </div>
              )}
              <a
                href={currentResumeUrl}
                download
                className={`bg-green-600 text-white px-4 py-2 rounded ${
                  numPages > 1 ? 'md:absolute' : 'relative'
                } md:right-0 hover:bg-green-700 transition-all w-full md:w-auto text-center`}
              >
                Download
              </a>
            </div>
          </div>
          {loading && (
            <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
              <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ResumeUploadModal;
