import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatStringForFrontend } from '../../../utils/CommonFunctions';
import api from '../../../utils/api';

const TestProcesses = ({ applicationData, isHired }) => {
  const navigate = useNavigate();
  const { orgName, jobId, appId } = useParams();
  const [testProcess, setTestProcess] = useState([]);
  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  useEffect(() => {
    fetchCandidateTestProcess();
  }, []);

  const fetchCandidateTestProcess = async () => {
    try {
      const response = await api.get(
        `/v1/organization/${orgId}/job/${jobId}/application/${appId}/candidate/test-process`
      );

      if (response.statusText === 'OK') {
        setTestProcess(response.data.data);
      } else {
        throw new Error(response.message || 'API issue');
      }
    } catch (error) {
      toast.error(error.message);
      console.error('Fetch error: ', error.message);
    }
  };

  const startTest = async (testProcessId) => {
    try {
      const { data } = await api.post(
        `/v1/organization/${orgId}/job/${jobId}/application/${appId}/candidate/test-process/${testProcessId}/start`
      );

      const personalityTestId = data?.details?.personalityTestData?._id;
      if (personalityTestId) {
        navigate(
          `/${orgName}/personality-tests/${personalityTestId}/?jobId=${jobId}&appId=${appId}&testProcessId=${testProcessId}`
        );
      }
    } catch (error) {
      console.error('Error fetching questions', error);
      toast.error(error.message);
    }
  };

  const getStatusComponent = (status) => {
    const statusConfig = {
      NOT_STARTED: ['bg-[#ffc009]', 'text-black', 'Not Started'],
      PENDING: ['bg-[#ffc009]', 'text-black', 'Not Started'],
      IN_PROGRESS: ['bg-[#ffc009]', 'text-black', 'In Progress'],
      FAILED: ['bg-red-500', 'text-white', 'Failed'],
      COMPLETED: ['bg-green-500', 'text-white', 'Completed']
    };

    if (isHired) {
      return status === 'COMPLETED'
        ? ['bg-green-500', 'text-white', 'Completed']
        : ['bg-gray-400', 'text-white', 'Not Required'];
    }

    const [bgColor, textColor, label] = statusConfig[status] || [
      '',
      '',
      'Unknown status'
    ];
    return [bgColor, textColor, label];
  };

  const renderTestStatus = (status) => {
    const [bgColor, textColor, label] = getStatusComponent(status);
    return label === 'Unknown status' ? (
      <p>{label}</p>
    ) : (
      <span
        className={`${bgColor} ${textColor} py-1 px-3 rounded-full text-xs`}
      >
        {label.replace(' ', '\u00A0')}
      </span>
    );
  };

  const findObjectWithType = (type) =>
    testProcess.find((item) => item.testProcess.type === type);

  const handleTestStart = (process) => {
    const routeMap = {
      AI_ANALYSIS: `/${orgName}/questions/${jobId}/${appId}`,
      SKILL_BASED_MCQ_TEST: `/${orgName}/preassesment/${jobId}/${appId}/${process._id}`,
      APTITUDE_MCQ_TEST: `/${orgName}/aptitude/${jobId}/${appId}/${process._id}`,
      PERSONALITY_TEST: () => startTest(process._id)
    };

    const action = routeMap[process.testProcess.type];
    typeof action === 'function' ? action() : navigate(action);
  };

  const canStartTest = (process, index) => {
    if (
      isHired ||
      !['NOT_STARTED', 'PENDING', 'IN_PROGRESS'].includes(process.status)
    ) {
      return false;
    }

    const isFirstTest = process.testProcess.type === 'AI_ANALYSIS';
    const isPreviousCompleted =
      index > 0 &&
      findObjectWithType(testProcess[index - 1].testProcess.type)?.status ===
        'COMPLETED';
    const isNotInProgressMCQ = !(
      process.status === 'IN_PROGRESS' &&
      process.testProcess.type === 'SKILL_BASED_MCQ_TEST'
    );

    return (isFirstTest || isPreviousCompleted) && isNotInProgressMCQ;
  };

  const shouldShowMCQNote = (testType) => {
    const test = findObjectWithType(testType);
    return (
      test &&
      findObjectWithType('AI_ANALYSIS')?.status === 'COMPLETED' &&
      ['PENDING', 'IN_PROGRESS'].includes(test.status)
    );
  };

  const renderLoadingSkeleton = () => (
    <ul className="list-none animate-pulse">
      {[...Array(3)].map((_, index) => (
        <li
          key={index}
          className="flex justify-between items-center bg-gray-100 p-3 rounded mb-2 relative h-[66px]"
        >
          <div className="w-1/2">
            <div className="bg-gray-200 rounded-full h-4 w-1/2" />
            <div className="bg-gray-200 rounded-full h-[15px] w-full" />
          </div>
          <div className="absolute right-5 top-6">
            <button className="bg-gray-200 rounded h-6 w-20" />
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="card mt-5">
      <div className="p-4">
        <p className="text-gray-500 mb-4">
          Please complete the following assessments to proceed with your
          application.
        </p>

        {testProcess.length ? (
          <ul className="list-none">
            {testProcess.map((process, index) => (
              <li
                key={process._id}
                className="flex justify-between items-center bg-gray-100 p-3 rounded mb-2 relative hover-highlight max-sm:flex-col max-sm:items-center max-sm:gap-2"
              >
                <div className="max-sm:flex max-sm:flex-col max-sm:items-center max-sm:gap-2">
                  <h6 className="text-sm font-medium max-sm:flex max-sm:flex-col-reverse max-sm:items-center max-sm:gap-2">
                    {formatStringForFrontend(process.testProcess.type)}{' '}
                    {renderTestStatus(process.status)}
                  </h6>
                  <small className="text-gray-500 max-sm:text-center">
                    {isHired
                      ? 'Already Hired. No need to GiveTest.'
                      : ['FAILED', 'COMPLETED'].includes(process.status)
                        ? 'This step has been completed successfully.'
                        : 'Not started. Please begin the test when ready.'}
                  </small>
                </div>
                {canStartTest(process, index) && (
                  <button
                    className="bg-primary-500 text-white text-lg w-32 py-1 px-2 max-sm:my-2 rounded shadow-lg"
                    onClick={() => handleTestStart(process)}
                  >
                    Start Test
                  </button>
                )}
              </li>
            ))}
          </ul>
        ) : (
          renderLoadingSkeleton()
        )}

        {/* MCQ Note */}
        {(shouldShowMCQNote('SKILL_BASED_MCQ_TEST') ||
          shouldShowMCQNote('APTITUDE_MCQ_TEST')) && (
          <div
            className="bg-primary-100 border-l-4 border-primary-500 text-primary-700 p-4 mt-4"
            role="alert"
          >
            <strong>Note:</strong> Once you start the{' '}
            {shouldShowMCQNote('APTITUDE_MCQ_TEST')
              ? 'Aptitude'
              : 'Skill-Based'}{' '}
            MCQ Assessment, you will have a limited amount of time to complete
            it. Ensure you are prepared before beginning.
          </div>
        )}
      </div>
    </div>
  );
};

export default TestProcesses;
