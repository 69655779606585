import React, { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import login from '../../api/login';
import signup from '../../api/signup';
import forgotPassword from '../../api/iam/forgotPassword';
import resetPassword from '../../api/iam/resetPassword';
import { useDispatch } from 'react-redux';
import LoaderWithText from '../../utils/LoaderWithText';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';

const LoginModal = ({
  isOpen,
  toggleModal,
  onClose,
  jobId = null,
  fetchAllApplications = () => {}
}) => {
  const [modalType, setModalType] = useState('Login');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    otp: ''
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });
  const [loading, setLoading] = useState(false);
  const [isInitialOtpReceived, setIsInitialOtpReceived] = useState(false);
  const [enableResendOtp, setEnableResendOtp] = useState(false);
  const [requestForOtpCalled, setRequestForOtpCalled] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { orgName } = useParams();
  const firstInputRef = useRef(null);
  const recaptchaRef = useRef(null);
  const isPersonalityTestRoute = location.pathname.includes(
    `/${orgName}/personality-tests/`
  );
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const container = document.getElementById('authModal');
    if (!container) {
      return;
    }

    const focusableElements = container.querySelectorAll(
      'input, button, select, textarea, a[href]'
    );
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    const handleKeyDown = (e) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        // Shift + Tab
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        // Tab
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    };

    container.addEventListener('keydown', handleKeyDown);

    return () => {
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const resetRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setModalType('Login');
    setFormData({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      otp: ''
    });
    setIsInitialOtpReceived(false);
    setEnableResendOtp(false);
    setRequestForOtpCalled(false);
    setLoading(false);
    setShowPassword({ password: false, confirmPassword: false });
    onClose();
  };

  const navigateToJobOrHome = () => {
    if (jobId) {
      navigate(`/${orgName}/job/${jobId}`);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest('.grecaptcha-popup')) {
        setLoading(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleFormSubmission = async (recaptchaValue) => {
    try {
      if (modalType === 'Login') {
        const res = await login(formData.email, formData.password);
        dispatch({ type: 'LOGIN', payload: { role: res.role } });
        navigateToJobOrHome();
        handleClose();
        toast.success('Logged in successfully');
        fetchAllApplications();
      } else if (modalType === 'Signup') {
        if (formData.password !== formData.confirmPassword) {
          toast.error('Password and Confirm Password do not match');
          resetRecaptcha();
          return;
        }
        if (!recaptchaValue) {
          toast.error('reCAPTCHA verification failed. Please try again.');
          resetRecaptcha();
          return;
        }

        await signup({
          name: formData.name,
          email: formData.email,
          password: formData.password,
          gRecaptchaResponse: recaptchaValue
        });
        dispatch({ type: 'LOGIN', payload: { role: 'CANDIDATE' } });
        navigateToJobOrHome();
        handleClose();
        toast.success('Signed up successfully');
      } else if (modalType === 'Forgot Password') {
        if (isInitialOtpReceived) {
          if (formData.password !== formData.confirmPassword) {
            toast.error('New Password and Confirm New Password do not match');
            resetRecaptcha();
            return;
          }
          if (!recaptchaValue) {
            toast.error('reCAPTCHA verification failed. Please try again.');
            resetRecaptcha();
            return;
          }

          await resetPassword(
            formData.email,
            formData.otp,
            formData.password,
            recaptchaValue
          );
          switchModalType('Login');
          toast.success('Password reset successfully');
          resetRecaptcha();
        } else {
          await handleGenerateOTP();
        }
      }
    } catch (err) {
      resetRecaptcha();
      toast.error(err.message || 'An error occurred. Please try again.');
      throw err;
    }
  };

  const handleLoginAndSignup = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (
        modalType === 'Signup' ||
        (modalType === 'Forgot Password' && isInitialOtpReceived)
      ) {
        const recaptchaValue = await recaptchaRef.current.executeAsync();
        await handleFormSubmission(recaptchaValue);
      } else {
        await handleFormSubmission(null);
      }
    } catch (error) {
      console.error('Error:', error);
      if (
        modalType === 'Signup' ||
        (modalType === 'Forgot Password' && isInitialOtpReceived)
      ) {
        resetRecaptcha();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateOTP = async () => {
    setRequestForOtpCalled(true);
    setLoading(true);
    try {
      await forgotPassword(formData.email);
      setIsInitialOtpReceived(true);
      setEnableResendOtp(false);
      toast.success('OTP sent successfully');
    } catch (err) {
      toast.error(err.message || 'Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
      setRequestForOtpCalled(false);
    }
  };

  const switchModalType = (newType) => {
    setModalType(newType);
    setFormData({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      otp: ''
    });
    setIsInitialOtpReceived(false);
    setEnableResendOtp(false);
    setRequestForOtpCalled(false);
    setShowPassword({ password: false, confirmPassword: false });
    resetRecaptcha();
  };

  useEffect(() => {
    if (isOpen && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [isOpen, modalType]);

  useEffect(() => {
    resetRecaptcha();
  }, [modalType]);

  useEffect(() => {
    if (modalType === 'Forgot Password' && isInitialOtpReceived) {
      document.getElementById('otp').focus();
    }
  }, [modalType, isInitialOtpReceived]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="modal fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
      onClick={toggleModal}
      id="authModal"
    >
      <div
        className="bg-white p-6 rounded shadow-lg max-w-md w-full relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-3 pb-3 border-b">
          <h2 className="text-2xl font-bold">
            {modalType === 'Forgot Password' && isInitialOtpReceived
              ? 'Reset Password'
              : modalType}
          </h2>
          {!isPersonalityTestRoute && (
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center z-50"
              onClick={handleClose}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          )}
        </div>

        <form className="w-full" onSubmit={handleLoginAndSignup}>
          {modalType === 'Signup' && (
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                ref={firstInputRef}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Name"
                required
              />
            </div>
          )}
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              ref={modalType !== 'Signup' ? firstInputRef : null}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Email"
              required
              disabled={modalType === 'Forgot Password' && isInitialOtpReceived}
            />
          </div>
          {modalType === 'Forgot Password' && isInitialOtpReceived && (
            <div className="mb-4">
              <label htmlFor="otp" className="block mb-2">
                OTP
              </label>
              <div className="flex justify-between items-center">
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  value={formData.otp}
                  onChange={handleInputChange}
                  className="w-[40%] p-2 border border-gray-300 rounded"
                  required
                />
                {!enableResendOtp && (
                  <LoaderWithText
                    restart={!enableResendOtp}
                    allowBlink={false}
                    onComplete={() => setEnableResendOtp(true)}
                  />
                )}
                <button
                  type="button"
                  className={`px-4 py-2 bg-primary-500 text-white rounded ${
                    !enableResendOtp || !!requestForOtpCalled
                      ? 'cursor-not-allowed opacity-50'
                      : 'hover:bg-primary-700'
                  }`}
                  disabled={!enableResendOtp}
                  onClick={(e) => {
                    e.preventDefault();
                    handleGenerateOTP();
                  }}
                >
                  Resend OTP
                </button>
              </div>
            </div>
          )}
          {(modalType !== 'Forgot Password' || isInitialOtpReceived) && (
            <div className="mb-4 w-full">
              <label htmlFor="password" className="block mb-2">
                {modalType === 'Forgot Password' ? 'New ' : ''}Password
              </label>
              <div className="flex items-center">
                <input
                  type={showPassword.password ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="p-2 w-full border border-gray-300 rounded"
                  placeholder="Password"
                  disabled={loading}
                  required
                />
                <button
                  type="button"
                  className="absolute right-[34px] focus:outline-none ml-2 flex items-center justify-center rounded-md"
                  tabindex="-1"
                  onClick={() =>
                    setShowPassword((prev) => ({
                      ...prev,
                      password: !prev.password
                    }))
                  }
                >
                  {showPassword.password ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </div>
          )}
          {(modalType === 'Signup' ||
            (modalType === 'Forgot Password' && isInitialOtpReceived)) && (
            <div className="mb-4 w-full">
              <label htmlFor="confirm-password" className="block mb-2">
                Confirm {modalType === 'Forgot Password' ? 'New ' : ''}
                Password
              </label>
              <div className="flex items-center">
                <input
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  id="confirm-password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className="p-2 w-full border border-gray-300 rounded"
                  placeholder="Confirm Password"
                  disabled={loading}
                  required
                />
                <button
                  type="button"
                  className="absolute right-[34px] focus:outline-none flex items-around justify-center rounded-md"
                  tabindex="-1"
                  onClick={() =>
                    setShowPassword((prev) => ({
                      ...prev,
                      confirmPassword: !prev.confirmPassword
                    }))
                  }
                >
                  {showPassword.confirmPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </div>
          )}
          {modalType !== 'Signup' && (
            <div className="mb-4 text-center">
              <span>
                {modalType === 'Login' ? 'Forgot your Password?' : 'Go to'}{' '}
                <button
                  type="button"
                  className="text-primary-500 hover:underline"
                  onClick={() =>
                    switchModalType(
                      modalType === 'Login' ? 'Forgot Password' : 'Login'
                    )
                  }
                  disabled={loading}
                >
                  {modalType === 'Login' ? 'Reset Password' : 'Login'}
                </button>
              </span>
            </div>
          )}
          {modalType !== 'Forgot Password' && (
            <div className="mb-4 text-center">
              <span>
                {modalType === 'Login'
                  ? "Don't have an account?"
                  : 'Already have an account?'}{' '}
                <button
                  type="button"
                  className="text-primary-500 hover:underline"
                  onClick={() =>
                    switchModalType(modalType === 'Login' ? 'Signup' : 'Login')
                  }
                >
                  {modalType === 'Login' ? 'Signup' : 'Login'}
                </button>
              </span>
            </div>
          )}
          <div className="flex items-center justify-center">
            <button
              type="submit"
              disabled={loading}
              className="min-w-24 h-11 flex justify-center bg-primary-600 text-white py-2 px-4 rounded hover:bg-primary-700"
            >
              {loading ? (
                <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
              ) : modalType === 'Forgot Password' ? (
                isInitialOtpReceived ? (
                  'Change Password'
                ) : (
                  'Request OTP'
                )
              ) : (
                modalType
              )}
            </button>
          </div>
        </form>
        {(modalType === 'Signup' || modalType === 'Forgot Password') && (
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            size="invisible"
            ref={recaptchaRef}
          />
        )}
      </div>
    </div>
  );
};

export default LoginModal;
