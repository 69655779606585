import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import ApplyJobModal from '../ApplyJobModal';
import { useDispatch, useSelector } from 'react-redux';
import LoginModal from '../LoginModal/index';
import ResumeUploadModal from '../ResumeUploadModal';
import { useRef } from 'react';
import jobSpecificDetails from '../../constants/jobSpecificDetails';
import { FaArrowRightLong } from 'react-icons/fa6';
import {
  formatStringToCamelCase,
  formatStringForFrontend,
  toggleScrollBarFormRootHTMLTag
} from '../../utils/CommonFunctions';
import { toast } from 'react-toastify';
import InstructionsModal from '../InstructionsModal';
import { useHelperBot } from '../HelperBot';
import api from '../../utils/api';

const fieldsToRender = [
  'Work Hours',
  'Minimum Experience',
  'Location',
  'Salary Budget'
];

const JobDetail = () => {
  const uselocation = useLocation();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [isCopied, setIsCopied] = useState(false);
  const inputRef = useRef(null);
  const { orgName } = useParams();
  const [jobData, setJobData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showApplyJobModal, setShowApplyJobModal] = useState(false);
  const [isResumeUploadModalOpen, setIsResumeUploadModalOpen] = useState(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);
  const [renderApplySection, setRenderApplySection] = useState(true);
  const [userRole, setUserRole] = useState('');
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const appliedJobsObj = useSelector((state) => state.appliedJobsObj);
  const dispatch = useDispatch();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [onGoingApplication, setOnGoingApplication] = useState({});
  const [acceptedApplication, setAcceptedApplication] = useState({});
  const [otherOnGoingApplication, setOtherOnGoingApplication] = useState(false);
  const [
    isAtLeastOnApplicationInProgress,
    setIsAtLeastOnApplicationInProgress
  ] = useState(false);
  const [isUserAccepted, setIsUserAccepted] = useState(false);
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);
  const [isLoadingApplications, setIsLoadingApplications] = useState(false);

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  const { helperMessage } = useHelperBot();

  useEffect(() => {
    if (isLoginModalOpen) {
      helperMessage(
        'Okay, we’re almost there! Just log in or sign up real quick so we can keep going. I promise it’ll only take a sec!'
      );
      return;
    }
    if (isInstructionsModalOpen || isResumeUploadModalOpen) {
      helperMessage(
        'Alright, let’s get your resume in there! You can upload a new one or pick one you’ve used before. Don’t worry, I’ll wait!'
      );
      return;
    }

    if (showApplyJobModal) {
      helperMessage(
        'I’ve filled in most of this for you—just a few more details, and we’re good to go. You’re so close!'
      );
      return;
    }

    helperMessage(
      'This one looks interesting, doesn’t it? Take your time to check it out, and if it feels right, hit that apply button. I’ve got your back!'
    );
  }, [
    isLoginModalOpen,
    isInstructionsModalOpen,
    isResumeUploadModalOpen,
    showApplyJobModal
  ]);

  useEffect(() => {
    const status = appliedJobsObj?.[jobId]?.status;

    if (status === 'REJECTED') {
      helperMessage(
        "Oops! It looks like this one wasn't the right fit, but don't worry—there are plenty more opportunities waiting for you! Keep exploring, and the perfect role will come your way."
      );
    } else if (status === 'HIRED') {
      helperMessage(
        "Congratulations! You've successfully passed the assessments, and we’re excited to move forward with your application. We’ll be in touch soon with the next steps. Welcome aboard!"
      );
    } else if (
      status === 'APPLICATION_SUBMITTED' ||
      status === 'PROFILE_REVIEW_COMPLETED' ||
      status === 'AI_REVIEW_COMPLETED' ||
      status === 'ASSESSMENTS_IN_PROGRESS'
    ) {
      helperMessage(
        "You're currently in the testing phase of your application. Please complete your test, and we will update you on the next steps once it's submitted. Good luck!"
      );
    }
  }, [appliedJobsObj, jobId]);

  const fetchAllApplicationUserHaveAppliedFor = async () => {
    setIsLoadingApplications(true);
    try {
      const url = `/v1/organization/${orgId}/applied-applications`;
      const response = await api.get(url);
      const data = response.data;

      if (data.statusCode === 200) {
        let jobsObj = {};
        const sortedApplications = data?.data?.applications.sort(
          (a, b) => new Date(b.applicationDate) - new Date(a.applicationDate)
        );

        sortedApplications?.forEach((application) => {
          if (
            !jobsObj[application.job] ||
            new Date(application.applicationDate) >
              new Date(jobsObj[application.job].applicationDate)
          ) {
            jobsObj[application.job] = { ...application };
          }
        });

        if (jobsObj[jobId]) {
          setIsApplyDisabled(
            jobsObj[jobId].status === 'REJECTED' ||
              jobsObj[jobId].status === 'IN_PROGRESS'
          );
        } else {
          setIsApplyDisabled(false);
        }

        dispatch({ type: 'SET_APPLIED_JOBS_OBJ', payload: jobsObj });
      } else {
        console.error('Failed to fetch applications:', data.message);
        setIsApplyDisabled(false);
      }
    } catch (error) {
      setIsApplyDisabled(false);
      console.error('Error fetching applications:', error);
      let areNoApplicationsFound =
        error.response?.data?.message ===
        'No applications found for this user.';
      if (!areNoApplicationsFound) {
        navigate(`/${orgName}/404`);
      }
    } finally {
      setIsLoadingApplications(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsApplyDisabled(true);
      fetchAllApplicationUserHaveAppliedFor();
    } else {
      setIsApplyDisabled(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setOnGoingApplication(
      Object.values(appliedJobsObj).find(
        (app) => app.status !== 'REJECTED'
        // app.status === "REJECTED" ||
        // app.status === "APPLICATION_SUBMITTED" ||
        // app.status === "PROFILE_REVIEW_COMPLETED" ||
        // app.status === "AI_REVIEW_COMPLETED" ||
        // app.status === "ASSESSMENTS_IN_PROGRESS"
      )
    );
  }, [appliedJobsObj, jobId]);

  useEffect(() => {
    setOtherOnGoingApplication(
      Object.values(appliedJobsObj).find(
        (app) => app.job !== jobId && app.status !== 'REJECTED'
      ) || false
    );
  }, [appliedJobsObj, jobId]);

  useEffect(() => {
    const inProgressStatusCodeList = [
      'APPLICATION_SUBMITTED',
      'PROFILE_REVIEW_COMPLETED',
      'AI_REVIEW_COMPLETED',
      'ASSESSMENTS_IN_PROGRESS'
    ];
    setIsAtLeastOnApplicationInProgress(
      Object.values(appliedJobsObj).find(
        (app) =>
          app.job !== jobId && inProgressStatusCodeList.includes(app.status)
      ) || false
    );
  }, [appliedJobsObj, jobId]);

  const checkIfUserAcceptedForAnyJob = useMemo(() => {
    return Object.values(appliedJobsObj).find(
      (job) => job.status !== 'REJECTED'
    );
  }, [appliedJobsObj]);

  useEffect(() => {
    setAcceptedApplication(checkIfUserAcceptedForAnyJob);
    setIsUserAccepted(!!checkIfUserAcceptedForAnyJob);
  }, [checkIfUserAcceptedForAnyJob]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUserRole(user?.role || '');

    const fetchJobDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`
        );
        const data = await response.json();
        if (data.statusCode === 200) {
          let reqData = data.data;
          const responsibilities = reqData.responsibilities;
          const responsibilitiesArray = responsibilities
            .split('. ')
            .map((item) => item.trim())
            .filter((item) => item);
          reqData['responsibilities'] = responsibilitiesArray;
          setJobData(reqData);
        } else {
          toast.error(data.message || 'An error occurred');
          throw new Error('API issue');
        }
      } catch (error) {
        console.error('Error fetching Job details :', error);
        navigate(`/${orgName}/404`);
      }
    };

    if (jobId) {
      fetchJobDetails();
    }
  }, [jobId]);

  const hasUserApplied =
    appliedJobsObj?.[jobId]?.status === 'ASSESSMENTS_COMPLETED' || false;

  const wasUserRejected =
    (appliedJobsObj?.[jobId] &&
      appliedJobsObj?.[jobId].status === 'REJECTED') ||
    false;

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCopy = () => {
    const inputValue = inputRef.current.value;
    navigator.clipboard.writeText(inputValue);
    setIsCopied(true);
    toast.success('Job Url copied to clipboard');
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleCloseInstructionsModal = () => {
    setIsInstructionsModalOpen(false);
    toggleScrollBarFormRootHTMLTag(false);
  };

  const handleApplyClick = () => {
    if (isLoggedIn) {
      setIsInstructionsModalOpen(true);
    } else {
      setIsLoginModalOpen(true);
    }
    toggleScrollBarFormRootHTMLTag(true);
  };

  const handleProceedToApply = () => {
    setIsInstructionsModalOpen(false);
    setIsResumeUploadModalOpen(true);
    toggleScrollBarFormRootHTMLTag(true);
  };

  const handleResumeUploadModalClose = () => {
    setIsResumeUploadModalOpen(false);
    toggleScrollBarFormRootHTMLTag(false);
  };

  const handleContinueApplication = () => {
    const { status, job, _id: id } = onGoingApplication || {};
    navigate(`/${orgName}/appliedjob/${job}/${id}`);

    let arr = [
      'I’m matching up your info with the job. Hang on just a little longer, we’re getting there!',
      'Still with me? Awesome! I’m just making sure you’re a great fit.',
      'Almost done! We’ll be moving on in just a sec.'
    ];
    helperMessage(arr[Math.floor(Math.random() * arr.length)]);
  };

  const handleOpenJob = () => {
    const { status, job, _id: id } = onGoingApplication || {};
    navigate(`/${orgName}/job/${job}`);
  };

  const handleOpenAcceptedJob = () => {
    const { status, job, _id: id } = acceptedApplication || {};
    navigate(`/${orgName}/job/${job}`);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isInstructionsModalOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isInstructionsModalOpen]);

  if (!jobData || isLoadingApplications) {
    return (
      <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
        <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  const {
    title,
    createdAt,
    workHours,
    salaryBudget,
    location,
    companyName,
    description,
    responsibilities,
    qualifications,
    skills,
    minimumExperience,
    uniqueId,
    testProcesses
  } = jobData;

  const date = new Date(createdAt);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const formatWorkHours = (workHours) => {
    return workHours
      .split('_')
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join('-');
  };

  const formattedWorkHours = formatWorkHours(workHours);

  const handleLoginCloseModal = () => {
    setIsLoginModalOpen(false);
    toggleScrollBarFormRootHTMLTag(false);
  };

  const onInstructionsSubmit = () => {
    setIsInstructionsModalOpen(false);
    setIsResumeUploadModalOpen(true);
    // setIsModalOpen(true);
  };
  const renderResumeText = () => {
    // if(location.pathname === "/" ||
    // location.pathname.includes("/job/"))
  };

  return (
    <>
      <div className="bg-[url('images/headerbg.png')] bg-no-repeat bg-center bg-cover h-[304px] relative bg-primary-600 z-[1]">
        {onGoingApplication &&
          Object.keys(onGoingApplication).length > 0 &&
          (uselocation.pathname === `/${orgName}` ||
            uselocation.pathname.includes(`${orgName}/job/`)) && (
            <div className="absolute top-[4.6rem] flex items-center justify-center z-10  mx-auto w-full px-5 sm:px-0">
              <div className="container bg-white rounded-full flex items-center justify-center text-center font-semibold p-4 my-4 leading-4 text-xs md:text-base	">
                <span>
                  You have already applied to&nbsp;
                  {onGoingApplication.job == jobId ? (
                    'this job'
                  ) : (
                    <span
                      className="text-primary-600 cursor-pointer hover:text-primary-500 hover:underline"
                      onClick={handleOpenJob}
                    >
                      another job
                    </span>
                  )}
                  . To resume your application,&nbsp;
                  <span
                    className="text-primary-600 cursor-pointer hover:text-primary-500 hover:underline"
                    onClick={handleContinueApplication}
                  >
                    click here
                  </span>
                  .
                </span>
              </div>
            </div>
          )}
      </div>
      <div
        id="jobDetail"
        className="container mx-auto px-5 md:px-0 -mt-[140px] relative z-[1] mb-20"
      >
        <div className="grid grid-cols-12 lg:gap-12">
          <div className="col-span-12 xl:w-full xl:col-span-8">
            <div className="p-8 rounded bg-white shadow-lg ">
              <div className="flex items-center justify-between">
                <Link
                  to={`/${orgName}`}
                  state={{ jobId }}
                  className="bg-gray-300 px-3 py-2.5 text-sm font-medium rounded flex items-center gap-2.5 hover:bg-primary-600 hover:text-white transition hover:duration-500 w-fit"
                >
                  Back
                </Link>
                <button
                  data-tooltip-target="tooltip-website-url"
                  data-copy-to-clipboard-target="website-url"
                  className="flex items-center gap-2.5 bg-gray-300 px-3 py-2.5 text-sm font-medium rounded hover:bg-primary-600 hover:text-white transition hover:duration-500 w-fit xl:hidden"
                  type="button"
                  onClick={handleCopy}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 26 26"
                    width="20px"
                    height="20px"
                  >
                    <path d="M 21 0 C 18.238281 0 16 2.238281 16 5 C 16 5.085938 16.027344 5.164063 16.03125 5.25 L 8.1875 9.1875 C 7.320313 8.457031 6.222656 8 5 8 C 2.238281 8 0 10.238281 0 13 C 0 15.761719 2.238281 18 5 18 C 6.222656 18 7.320313 17.542969 8.1875 16.8125 L 16.03125 20.75 C 16.027344 20.835938 16 20.914063 16 21 C 16 23.761719 18.238281 26 21 26 C 23.761719 26 26 23.761719 26 21 C 26 18.238281 23.761719 16 21 16 C 19.777344 16 18.679688 16.457031 17.8125 17.1875 L 9.96875 13.25 C 9.972656 13.164063 10 13.085938 10 13 C 10 12.914063 9.972656 12.835938 9.96875 12.75 L 17.8125 8.8125 C 18.679688 9.542969 19.777344 10 21 10 C 23.761719 10 26 7.761719 26 5 C 26 2.238281 23.761719 0 21 0 Z" />
                  </svg>
                  <span>Share</span>
                </button>
              </div>

              <div className="flex flex-col items-start justify-between md:flex-row md:items-center gap-6 mt-6 pb-6 xl:border-b w-full">
                {}
                <div className="w-full">
                  <p className="text-base font-medium text-base-content">
                    {companyName.charAt(0).toUpperCase() + companyName.slice(1)}
                  </p>
                  <h6 className="mt-1 text-3xl font-medium text-base-content w-full break-words">
                    {title}
                  </h6>
                </div>
                <div className="flex flex-col xl:hidden">
                  {onGoingApplication &&
                  Object.keys(onGoingApplication).length > 0 &&
                  onGoingApplication.job == jobId ? (
                    <button
                      className={`button bg-primary-600 hover:bg-primary-700 transition hover:duration-500 py-3 px-5 font-semibold text-white rounded flex items-center justify-center  ${
                        userRole === 'ADMIN' || userRole === 'SUPER_ADMIN'
                          ? 'cursor-not-allowed opacity-50'
                          : ''
                      }`}
                      type="submit"
                      onClick={handleContinueApplication}
                      disabled={
                        userRole === 'ADMIN' || userRole === 'SUPER_ADMIN'
                      }
                    >
                      <span>Resume Application</span>
                    </button>
                  ) : (
                    <>
                      {wasUserRejected ? (
                        <span className="mb-4 font-bold text-red-700 text-center">
                          Based on recent application, you are not a good fit
                          for this role.
                        </span>
                      ) : (
                        checkIfUserAcceptedForAnyJob && (
                          <span className="mb-4 font-bold text-green-700 text-center">
                            You have already applied for{' '}
                            {hasUserApplied ? (
                              'this job'
                            ) : (
                              <>
                                another{' '}
                                <span
                                  className="text-primary-600 cursor-pointer hover:text-primary-500 hover:underline"
                                  onClick={handleOpenAcceptedJob}
                                >
                                  job
                                </span>
                              </>
                            )}
                            . Expect a call from us soon.
                          </span>
                        )
                      )}

                      {/* {otherOnGoingApplication && (
                        <span className="mb-4 font-bold text-green-700 text-center">
                        You have already applied for this job. Expect a call from us soon.
                      </span>
                      )} */}
                      {wasUserRejected ||
                      (checkIfUserAcceptedForAnyJob && hasUserApplied) ? (
                        <button
                          disabled={
                            userRole === 'ADMIN' || userRole === 'SUPER_ADMIN'
                          }
                          className={`button bg-primary-600 hover:bg-primary-700 transition hover:duration-500 py-3 px-5 font-semibold text-white rounded flex items-center justify-center w-full ${
                            userRole === 'ADMIN' || userRole === 'SUPER_ADMIN'
                              ? 'cursor-not-allowed opacity-50'
                              : ''
                          }`}
                          type="submit"
                          onClick={() => {
                            if (Object.keys(appliedJobsObj).length) {
                              let job = Object.values(appliedJobsObj).find(
                                (item) => {
                                  return item.job === jobId;
                                }
                              );
                              let appId = job._id;
                              navigate(
                                `/${orgName}/appliedjob/${jobId}/${appId}`,
                                {
                                  state: {
                                    title: job.jobData.title,
                                    jobDescriptionSummary:
                                      job.jobData.jobDescriptionSummary,
                                    job: job.jobData,
                                    workLocationType:
                                      job.jobData.workLocationType,
                                    jobId
                                  }
                                }
                              );
                            }
                          }}
                        >
                          <span className="mr-4">View Application Details</span>
                        </button>
                      ) : (
                        <button
                          disabled={
                            userRole === 'ADMIN' ||
                            userRole === 'SUPER_ADMIN' ||
                            hasUserApplied ||
                            isApplyDisabled ||
                            otherOnGoingApplication
                          }
                          className={`button bg-primary-600 hover:bg-primary-700 transition hover:duration-500 py-3 px-5 font-semibold text-white rounded flex items-center justify-center w-full ${
                            userRole === 'ADMIN' ||
                            userRole === 'SUPER_ADMIN' ||
                            hasUserApplied ||
                            isApplyDisabled ||
                            otherOnGoingApplication
                              ? 'cursor-not-allowed opacity-50'
                              : ''
                          }`}
                          type="submit"
                          onClick={handleApplyClick}
                        >
                          <span className="mr-4">Apply for this Position </span>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="block xl:hidden">
                <div className="mt-3 ">
                  {jobSpecificDetails.map((field) => {
                    if (field.name === 'experience') {
                      const minExperience = jobData['minimumExperience'];
                      const maxExperience = jobData['maximumExperience'];

                      // Check if minExperience exists (0 or greater) and maxExperience exists (truthy value)
                      if (
                        (minExperience || minExperience === 0) &&
                        maxExperience
                      ) {
                        return (
                          <div className="flex items-center justify-between py-5 border-b ">
                            <p className="text-base">Experience</p>
                            <p
                              className="text-base font font-bold text-right"
                              style={{ maxWidth: '60%' }}
                            >
                              {field.valueRenderingFunction(
                                minExperience,
                                maxExperience
                              )}
                            </p>
                          </div>
                        );
                      }
                      // If only minExperience exists, render it alone
                      else if (minExperience || minExperience === 0) {
                        return (
                          <div className="flex items-center justify-between py-5 border-b ">
                            <p className="text-base">Minimum Experience</p>
                            <p
                              className="text-base font font-bold text-right"
                              style={{ maxWidth: '60%' }}
                            >
                              {field.valueRenderingFunction(minExperience)}
                            </p>
                          </div>
                        );
                      }
                    }
                    return (
                      <div className="flex items-center justify-between py-5 border-b ">
                        <p className="text-base">{field.displayName}</p>
                        <p
                          className="text-base font font-bold text-right"
                          style={{ maxWidth: '60%' }}
                        >
                          {field.valueRenderingFunction(
                            jobData[field.name],
                            field.name === 'jobType'
                              ? jobData['workHours']
                              : field.name === 'maximumSalary'
                                ? jobData['minimumSalary']
                                : field.name === 'minimumExperience'
                                  ? jobData['maximumExperience']
                                  : ''
                          )}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="mt-8 pb-8 border-b border-base-content/10">
                <h6 className="mt-8 text-xl font-bold text-base-content">
                  Overview
                </h6>
                <p className="mt-3 text-base-content/80 text-justify	">
                  {description}
                </p>
                <div className="mt-8">
                  <h6 className="text-xl font-bold text-base-content mb-2">
                    Requirements
                  </h6>
                  <ul
                    className="ml-6 mt-3 flex flex-col gap-3 text-justify	"
                    style={{ listStyle: 'disc' }}
                  >
                    {responsibilities.map((responsibility, index) => (
                      <li key={index}>{responsibility}</li>
                    ))}
                  </ul>
                </div>

                <div className="mb-8 mt-8">
                  <h6 className="text-xl font-bold mb-2">Qualifications</h6>
                  <ul
                    className="ml-6 mt-3 flex flex-col gap-3 text-justify	"
                    style={{ listStyle: 'disc' }}
                  >
                    {qualifications.split('. ').map((qualification, index) => (
                      <li key={index}>{qualification}</li>
                    ))}
                  </ul>
                </div>

                <div className="mt-8">
                  <h6 className="text-xl font-bold text-base-content">
                    Skill &amp; Experience
                  </h6>
                  <p className="skills-container leading-7 mt-3 flex gap-1 flex-wrap">
                    {skills.map((skill, index) => (
                      <span
                        key={index}
                        className="skill-tag bg-primary-600 text-white px-2 py-1.5 rounded text-sm"
                      >
                        {skill}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
              <div className="mt-8 flex flex-col md:flex-row items-center gap-5 md:gap-0 justify-center md:justify-between">
                <div>
                  <h6 className="text-xl font-semibold text-base-content">
                    Interested in this job?
                  </h6>
                </div>
                <button
                  type="submit"
                  onClick={handleApplyClick}
                  disabled={
                    userRole === 'ADMIN' ||
                    userRole === 'SUPER_ADMIN' ||
                    hasUserApplied ||
                    otherOnGoingApplication ||
                    isApplyDisabled ||
                    (checkIfUserAcceptedForAnyJob && hasUserApplied) ||
                    onGoingApplication?.job == jobId
                  }
                  className={`button bg-primary-600 hover:bg-primary-700 transition hover:duration-500 py-3 px-5 font-semibold text-white rounded flex items-center ${
                    userRole === 'ADMIN' ||
                    userRole === 'SUPER_ADMIN' ||
                    hasUserApplied ||
                    isApplyDisabled ||
                    otherOnGoingApplication ||
                    (checkIfUserAcceptedForAnyJob && hasUserApplied) ||
                    onGoingApplication?.job == jobId
                      ? 'cursor-not-allowed opacity-50 '
                      : ''
                  } `}
                >
                  Apply Now
                  <span>
                    <svg
                      className="text-xl"
                      width="18"
                      height="18"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.86409 2.59846C7.12037 2.34218 7.53588 2.34218 7.79216 2.59846L11.7297 6.53596C11.9859 6.79224 11.9859 7.20776 11.7297 7.46404L7.79216 11.4015C7.53588 11.6578 7.12037 11.6578 6.86409 11.4015C6.6078 11.1453 6.6078 10.7297 6.86409 10.4735L9.6813 7.65625H2.73438C2.37194 7.65625 2.07812 7.36244 2.07812 7C2.07812 6.63756 2.37194 6.34375 2.73438 6.34375H9.6813L6.86409 3.52654C6.6078 3.27026 6.6078 2.85474 6.86409 2.59846Z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          {renderApplySection && (
            <div className="hidden xl:block xl:col-span-4">
              <div class="p-2 w-full max-w-sm rounded bg-white shadow-lg flex flex-col mb-4">
                <div class="flex items-center">
                  <span class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg dark:bg-gray-600 dark:text-white dark:border-gray-600">
                    Job Link
                  </span>
                  <div class="relative w-full">
                    <input
                      id="website-url"
                      type="text"
                      aria-describedby="helper-text-explanation"
                      class="bg-gray-50 border border-e-0 border-gray-300 text-gray-500 dark:text-gray-400 text-sm border-s-0 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={
                        uniqueId
                          ? `https://talentswap.ai/#?q=${uniqueId}`
                          : 'NA'
                        // window.location.href
                      }
                      readonly
                      disabled
                      ref={inputRef}
                    />
                  </div>
                  <button
                    data-tooltip-target="tooltip-website-url"
                    data-copy-to-clipboard-target="website-url"
                    class="gap-3 flex-shrink-0 z-10 inline-flex items-center py-3 px-4 text-sm font-medium text-center text-white bg-primary-700 rounded-e-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 border border-primary-700 dark:border-primary-600 hover:border-primary-800 dark:hover:border-primary-700"
                    type="button"
                    onClick={handleCopy}
                  >
                    <span id="default-icon">
                      <svg
                        class="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 20"
                      >
                        <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                      </svg>
                    </span>
                    <span
                      id="success-icon"
                      className={`inline-flex items-center ${
                        isCopied ? 'block' : 'hidden'
                      }`}
                    >
                      <svg
                        class="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    id="tooltip-website-url"
                    role="tooltip"
                    className={`absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 ${
                      isCopied ? 'visible' : 'invisible'
                    }`}
                  >
                    <span id="default-tooltip-message">Copy link</span>
                    <span
                      id="success-tooltip-message"
                      className={isCopied ? 'block' : 'hidden'}
                    >
                      Copied!
                    </span>
                    <div class="tooltip-arrow" data-popper-arrow></div>
                  </div>
                </div>
              </div>

              <div className="p-8 rounded bg-white shadow-lg flex flex-col">
                {onGoingApplication &&
                Object.keys(onGoingApplication).length > 0 &&
                onGoingApplication.job == jobId ? (
                  <button
                    disabled={
                      userRole === 'ADMIN' || userRole === 'SUPER_ADMIN'
                    }
                    className={`button bg-primary-600 hover:bg-primary-700 transition hover:duration-500 py-3 px-5 font-semibold text-white rounded flex items-center justify-center w-full ${
                      userRole === 'ADMIN' || userRole === 'SUPER_ADMIN'
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                    type="submit"
                    onClick={handleContinueApplication}
                  >
                    <span className="mr-4">Resume Application</span>
                  </button>
                ) : (
                  <>
                    {wasUserRejected ? (
                      <span className="mb-4 font-bold text-red-700 text-center">
                        Based on recent application, you are not a good fit for
                        this role.
                      </span>
                    ) : (
                      checkIfUserAcceptedForAnyJob && (
                        <span
                          className={`mb-4 font-bold text-green-700 text-center ${
                            isAtLeastOnApplicationInProgress
                              ? 'text-primary-600'
                              : 'text-green-700'
                          }`}
                        >
                          You have already applied for{' '}
                          {hasUserApplied ? (
                            'this job'
                          ) : (
                            <>
                              another{' '}
                              <span
                                className="text-primary-600 cursor-pointer hover:text-primary-500 hover:underline"
                                onClick={handleOpenAcceptedJob}
                              >
                                job.
                              </span>
                            </>
                          )}
                          {!isAtLeastOnApplicationInProgress
                            ? 'Expect a call from us soon.'
                            : ''}
                        </span>
                      )
                    )}

                    {wasUserRejected ||
                    (checkIfUserAcceptedForAnyJob && hasUserApplied) ? (
                      <button
                        disabled={
                          userRole === 'ADMIN' || userRole === 'SUPER_ADMIN'
                        }
                        className={`button bg-primary-600 hover:bg-primary-700 transition hover:duration-500 py-3 px-5 font-semibold text-white rounded flex items-center justify-center w-full ${
                          userRole === 'ADMIN' || userRole === 'SUPER_ADMIN'
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                        }`}
                        type="submit"
                        onClick={() => {
                          if (Object.keys(appliedJobsObj).length) {
                            let job = Object.values(appliedJobsObj).find(
                              (item) => {
                                return item.job === jobId;
                              }
                            );
                            let appId = job._id;
                            navigate(
                              `/${orgName}/appliedjob/${jobId}/${appId}`,
                              {
                                state: {
                                  title: job.jobData.title,
                                  jobDescriptionSummary:
                                    job.jobData.jobDescriptionSummary,
                                  job: job.jobData,
                                  workLocationType:
                                    job.jobData.workLocationType,
                                  jobId
                                }
                              }
                            );
                          }
                        }}
                      >
                        <span className="mr-4">View Application Details</span>
                      </button>
                    ) : (
                      <button
                        disabled={
                          userRole === 'ADMIN' ||
                          userRole === 'SUPER_ADMIN' ||
                          hasUserApplied ||
                          isApplyDisabled ||
                          otherOnGoingApplication
                        }
                        className={`button bg-primary-600 hover:bg-primary-700 transition hover:duration-500 py-3 px-5 font-semibold text-white rounded flex items-center justify-center w-full ${
                          userRole === 'ADMIN' ||
                          userRole === 'SUPER_ADMIN' ||
                          hasUserApplied ||
                          isApplyDisabled ||
                          otherOnGoingApplication
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                        }`}
                        type="submit"
                        onClick={handleApplyClick}
                      >
                        <span className="mr-4">Apply for this Position </span>
                      </button>
                    )}
                  </>
                )}
                <div className="mt-3 ">
                  {jobSpecificDetails.map((field) => {
                    if (field.name === 'experience') {
                      const minExperience = jobData['minimumExperience'];
                      const maxExperience = jobData['maximumExperience'];

                      // Check if minExperience exists (0 or greater) and maxExperience exists (truthy value)
                      if (
                        (minExperience || minExperience === 0) &&
                        maxExperience
                      ) {
                        return (
                          <div className="flex items-center justify-between py-5 border-b ">
                            <p className="text-base">Experience</p>
                            <p
                              className="text-base font font-bold text-right"
                              style={{ maxWidth: '60%' }}
                            >
                              {field.valueRenderingFunction(
                                minExperience,
                                maxExperience
                              )}
                            </p>
                          </div>
                        );
                      }
                      // If only minExperience exists, render it alone
                      else if (minExperience || minExperience === 0) {
                        return (
                          <div className="flex items-center justify-between py-5 border-b ">
                            <p className="text-base">Minimum Experience</p>
                            <p
                              className="text-base font font-bold text-right"
                              style={{ maxWidth: '60%' }}
                            >
                              {field.valueRenderingFunction(minExperience)}
                            </p>
                          </div>
                        );
                      }
                    }
                    return (
                      <div className="flex items-center justify-between py-5 border-b ">
                        <p className="text-base">{field.displayName}</p>
                        <p
                          className="text-base font font-bold text-right"
                          style={{ maxWidth: '60%' }}
                        >
                          {field.valueRenderingFunction(
                            jobData[field.name],
                            field.name === 'jobType'
                              ? jobData['workHours']
                              : field.name === 'maximumSalary'
                                ? jobData['minimumSalary']
                                : field.name === 'minimumExperience'
                                  ? jobData['maximumExperience']
                                  : ''
                          )}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={handleLoginCloseModal}
        jobId={jobId}
        fetchAllApplications={fetchAllApplicationUserHaveAppliedFor}
      />
      <ApplyJobModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        jobId={jobId}
      />
      <InstructionsModal
        isOpen={isInstructionsModalOpen}
        onClose={handleCloseInstructionsModal}
        testProcess={testProcesses}
        // onSubmit={onInstructionsSubmit}
        onProceedToApply={handleProceedToApply}
      />
      <ResumeUploadModal
        isOpen={isResumeUploadModalOpen}
        onClose={handleResumeUploadModalClose}
        jobId={jobId}
        showApplyJobModal={showApplyJobModal}
        setShowApplyJobModal={setShowApplyJobModal}
      />
    </>
  );
};

export default JobDetail;
